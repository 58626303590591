import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import MainHeader from '../../components/main-header/main-header';
import PageHeader from '../../components/page-header/page-header';
import Button from '../../components/button/button';
import HintText from '../../components/hint-text/hint-text';
import MainFooter from '../../components/main-footer/main-footer';
import IconCheck from '../../images/check.svg';
import { DataContext } from '../../data/DataProvider';
import './index.scss';
import DefaultLayout from '../../components/layouts/default-layout';

const block = 'page-sucesso';
const Page = ({ location }) => {
  const { quotation, setQuotation } = useContext(DataContext);
  const [isSucess, setIsSucess] = useState(false);
  const [valueProtocol, setValueProtocol] = useState();

  useEffect(() => {
    setIsSucess(true);
    if (quotation && quotation?.protocolo) {
      setValueProtocol(quotation.protocolo);
      setQuotation({});
    }
  }, [quotation, quotation?.protocolo, setQuotation]);

  return (
    <DefaultLayout currentPagePath={location.pathname}>
      <main className={block}>
        <MainHeader />
        <PageHeader
          title="Sua proposta foi enviada para emissão"
          description={
            <span>
              Aguarde até 5 dias úteis para a emissão da apólice e envio por
              e-mail.
              <br />
              Caso não receba a apólice dentro do prazo estimado, entre em
              contato com nossa equipe através do e-mail:{' '}
              <strong>
                <a href="mailto:emissaoempresarial@akadseguros.com.br">
                  emissaoempresarial@akadseguros.com.br
                </a>
              </strong>
              <br />e informe o número de protocolo abaixo:
            </span>
          }
          breadCrumbs={[]}
          hide={true}
        />
        {isSucess && (
          <div className={`container--large`}>
            <HintText
              type={'success'}
              hintText={
                <div id="hint-text">
                  <div className={`row`}>
                    <div className={`col`}>
                      <img
                        src={IconCheck}
                        className={`icon`}
                        alt="Número de protocolo"
                      />
                    </div>
                    <div className={`col`}>
                      <p className={'txt-14'}>
                        <strong>Nº de protocolo</strong>
                      </p>
                      <p className={'txt-14'}>{valueProtocol}</p>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        )}
        <div className={`container--large`}>
          <Button
            theme={'primary'}
            className="button_cta"
            onClick={() => {
              window.open(
                `${process.env.GATSBY_URL_DIGITAL}/protector/ferramentas/cotacoes`,
                '_blank'
              );
            }}
          >
            Ver cotação no digital
          </Button>
          <Button
            theme={'secondary'}
            className="button_cta button--margin-left"
            onClick={() => {
              navigate('/');
            }}
          >
            Voltar para página inicial
          </Button>
        </div>
        <MainFooter />
      </main>
    </DefaultLayout>
  );
};

export default Page;
